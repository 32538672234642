// src/i18n/i18n.ts

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './locales/en/translation.json';
import itTranslation from './locales/it/translation.json';

i18n
  
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      it: { translation: itTranslation },
    },
    fallbackLng: 'en',
    lng: "it",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
export {};
