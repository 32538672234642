import "../global.css";
import React, { useEffect } from "react";
import {Link} from "react-router-dom";
import PipedriveForm from "../components/PipedriveForm";
import ProductWebForm from "../components/PipedriveForm";
import PageHeader from "../components/PageHeader";
import BlackButtons from "../common/BlackButtons";
import {isMobile, isTablet} from "react-device-detect";
import { t } from "i18next";
export default function Contact() {
    useEffect(() => {
        if (window.location.hash) {
          let newPath = window.location.pathname + window.location.search;
          if (newPath.endsWith('/')) {
            console.log("termina con /", newPath)
            newPath = newPath.slice(0, -1);
            console.log("newPath", newPath)
          }
          window.history.pushState("", document.title, newPath);
        }
      }, []);

    const contacts = [
        {
            title: "SALES",
            description:
                "commerciale@ribesdigilab.it",
            link: "mailto:commerciale@ribesdigilab.it",
        },
         {
            title: "ASSISTANCE",
            description:
                "ASSISTENZA@RIBESDIGILAB.IT",
            link: "mailto:assistenza@ribesdigilab.it",
        },
        {
            title: "GENERAL CONTACT",
            description:
                "info@ribesdigilab.it",
            link: "mailto:info@ribesdigilab.it",
        }
    ];
    return (
        <div>
          
            <div className="relative flex flex-col h-full mt-20 ">
                {/* BECOME A REVOLVER Section */}

                <div className="h-full flex flex-col animate-fadeIn delay-0 leading-12 z-40 bg-black">
                    {/* Your content here */}
                    <div
                        style={{ position: "absolute", top: 0, left: 0, width: "100%", zIndex: 10,  pointerEvents: "none" ,height:"100%" }}>
                        <PageHeader title={t("GET IN TOUCH")} subtitle={t("CURIOUS? REACH OUT, WE ARE ALWAYS HERE TO CHAT!")}/>

                    </div>

                    <div className="flex justify-center pt-72 pb-40"> <BlackButtons to1="/contact/#form-contacts"  description1={t("CONTACT US")} to2="/contact/#where" description2={t("WHERE TO FIND US")} /></div>

                </div>
            </div>

            {/* CONTACTS Section */}
            <div className="h-full flex flex-col bg-black  bg-opacity-50 z-50 mb-40 ">
                <h1
                    id='def-contacts'
                    className="opacity-0 animate-fadeIn delay-1 my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-medium text-white ">
                    {t("Or just say hello!")}
                </h1>
                <h1 className="opacity-0 animate-fadeIn delay-3 mb-24 -mt-8 text-2xl leading-100 tracking-tighter text-center uppercase font-light text-white ">
                    {t("CLICK ON THE email TO contact us!")}{" "}
                </h1>
                <div>
                <div
                    data-aos="fade-zoom-in"
                    data-aos-offset="300px"
                    data-aos-delay="0"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="600"
                    className="font-mono flex flex-col gap-4 mt-10 sm:mt-0 z-0 items-center ">                        {" "}
                        {/* Add top margin on mobile */}
                        {contacts.map((contact, index) => (
                            <a
                                key={index}
                                href={contact.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className=""
                            >
                                <div
                                    className="with-border relative group mx-10 p-4 transition-all duration-200 overflow-hidden">
                                    <div
                                        className="absolute  inset-0 transform -scale-x-3 -scale-y-3 group-hover:scale-100 transition-transform duration-200"></div>
                                    <div
                                        className="  relative z-40 flex  sm:flex-row justify-between items-center">
                                        {" "}
                                        {/* Make it a column on mobile */}
                                        <div className=" w-80">
                                            {" "}
                                            {/* Adjust as needed */}
                                            <h2 className="text-lg  text-white uppercase">
                                                {t(contact.title)}
                                            </h2>
                                            <p className="text-sm text-white uppercase">
                                                {t(contact.description)}
                                            </p>
                                        </div>
                                        <div
                                            className="opacity-0 group-hover:opacity-100 transition-opacity duration-200
                                                 absolute right-0 bottom-0"
                                        >
                                            <img
                                                className="h-6 w-6 fill-current text-blue-500"
                                                src="/commons/arrow_forward.svg"
                                                alt="arrow"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            </div>

            <div  id='form-contacts' className="pb-20" ></div>

            {/*FORM SECTION*/}
             <div className="max-w-md mx-auto mt-10 p-6 "  data-aos="fade-zoom-in"
                 data-aos-offset="300px"
                 data-aos-delay="0"
                 data-aos-easing="ease-in-sine"
                 data-aos-duration="600">
                 <h1
                     id='positions'
                     className="opacity-0 animate-fadeIn delay-2 my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-medium text-white ">
                     {t("CONTACT US")}
                 </h1>
                 <ProductWebForm
                     formId="95a0e1ff-e505-4b30-9253-a3f16bc466a6"
                     portalId="145047450"
                     region="eu1"
                 />
            </div>

            {/*MAPS + IMAGE SECTION*/}

            <div
                className="h-full w-full py-40 bg-black bg-center z-40 px-10   "
                style={{ backgroundImage: `url(/mole.svg)`, backgroundPosition: 'center ', backgroundRepeat: 'no-repeat'}}>

                <div id='where'>
                    <h1
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="0"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        id='positions'
                        className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-medium text-white   ">
                        {t("From turin with love ")}
                    </h1>
                    <h1
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="400"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        className="mb-24 -mt-8 text-2xl leading-120 tracking-tighter text-center uppercase font-light text-white  ">
                         {t("BUT YOU COULD ALWAYS FIND US HERE: ")}
                    </h1>
                </div>




                <div className="grid md:grid-cols-3 flex place-items-center md:gap-x-24 text-white mb-20 md:mx-[3rem] ">
                    
                    <Link to="https://g.co/kgs/zNPeDcK" target="_blank" rel="noopener noreferrer" >
                    <button
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="400"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        className="col-span-1 pb-5 hover:text-orange-500 transform transition-transform duration-200  ">

                        <div className="pb-10 h-52">
                            <img src="/contacts/stars2.svg" alt=""/>
                        </div>
                        <div className="md:text-left mix-blend-difference ">
                            <h2 className="font-bold text-2xl uppercase">RIBES digilab srl</h2>
                            <p className="font-mono uppercase">Piazza del Rosario 9<br/> 07100 SASSARI<br/> SARDEGNA </p>
                        </div>
                    </button>
                    </Link>


                    <Link to="https://g.co/kgs/2CquFY2" target="_blank" rel="noopener noreferrer" >
                    <button
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="400"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        className="col-span-1 pb-5 hover:text-orange-500 transform transition-transform duration-200  ">

                        <div className="pb-10 md:mr-2 flex justify-center h-52">
                            <img src="/contacts/star1.svg" alt=""/>
                        </div>
                        <div className="text-center mix-blend-difference ">
                            <h2 className="font-bold text-2xl uppercase">RIBES digilab srl</h2>
                            <p className="font-mono uppercase">Lungo Dora Colletta 81/A <br/> 10153 Torino <br/> Italy</p>
                        </div>
                    </button>
                    </Link>

                    <Link to="https://g.co/kgs/VFHkDVY" target="_blank" rel="noopener noreferrer" >
                    <button
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="400"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        className="col-span-1 pb-5 hover:text-orange-500 transform transition-transform duration-200">
                        <div className="pb-10 h-52 ">
                            <img className="md:ml-7 ml-3" src="/contacts/stars3.svg" alt=""/>
                        </div>
                        <div className="md:text-right">
                            <h2 className="font-bold text-2xl uppercase">Ribes digilab srl</h2>
                            <p className="font-mono uppercase">Corso Toscana 110/A <br/> 10149 Torino <br/>Italy</p>
                        </div>
                    </button>
                    </Link>

                    

                </div>
                {/*!isMobile && !isTablet && (<div className=" w-1/3 h-full">
                    <iframe src="https://storage.googleapis.com/maps-solutions-wocgqcgwpv/locator-plus/ylzc/locator-plus.html"
                            width="100%" height="100%"
                            style={{border:0}}
                            loading="eager">
                    </iframe>
                </div>)*/}
            </div>
            <div className="lg:col-2 lg:row-2 lg:flex lg:justify-center lg:items-center col-1 w-full">
            <div className="font-mono text-white text-2xl text-center mx-[3rem] md:items-left flex grid col-1 py-[7rem] "
            data-aos="fade-zoom-in"
            
            data-aos-delay="600"
            data-aos-easing="ease-in-sine"
            data-aos-duration="700">{t("administrative address:")}
                
            <iframe className="w-full mt-4 lg:justify-center lg:w-[27rem] xl:w-[35rem] 2xl:w-[45rem] h-[25rem] md:h-[35rem] lg:h-[25rem] border-white border-[0.3em] rounded shadow-lg shadow-gray-300"  src="https://www.openstreetmap.org/export/embed.html?bbox=7.710774242877961%2C45.0742157639503%2C7.713220417499542%2C45.075670452557056&amp;layer=mapnik&amp;marker=45.074943112882416%2C7.711997330188751" ></iframe><br/>
            <small><a className="hover:text-orange-500" href="https://www.openstreetmap.org/?mlat=45.074943&amp;mlon=7.711997#map=19/45.074943/7.711997"target="_blank">{t("click for fullscreen map")}</a></small>
            </div>


            <div className="font-mono flex text-white text-2xl text-center mx-[3rem] md:items-right flex grid col-2  "
            data-aos="fade-zoom-in"
            
            data-aos-delay="600"
            data-aos-easing="ease-in-sine"
            data-aos-duration="700">{t("OTHER ADDRESS:")}
            <iframe className="w-full mt-4 lg:w-[27rem] 2xl:w-[45rem] xl:w-[35rem] h-[25rem] md:h-[35rem] lg:h-[25rem] border-white border-[0.3em] rounded shadow-lg shadow-gray-300" src="https://www.openstreetmap.org/export/embed.html?bbox=7.650330662727357%2C45.097993761230725%2C7.652776837348939%2C45.0994478444365&amp;layer=mapnik&amp;marker=45.09872080746235%2C7.651553750038147"></iframe><br/>
            <small><a className="hover:text-orange-500" href="https://www.openstreetmap.org/?mlat=45.098721&amp;mlon=7.651554#map=19/45.098721/7.651554"target="_blank">{t("click for fullscreen map")}</a></small>

            </div>

            






            </div>
            <div className="font-mono flex text-white text-2xl text-center mx-[3rem] md:items-right flex lg:justify-center grid col-1 row-2  "
            data-aos="fade-zoom-in"
            
            data-aos-delay="600"
            data-aos-easing="ease-in-sine"
            data-aos-duration="700">{t("OTHER ADDRESS:")}
            <iframe className="w-full mt-4 lg:w-[27rem] 2xl:w-[45rem] xl:w-[35rem] h-[25rem] md:h-[35rem] lg:h-[25rem] border-white border-[0.3em] rounded shadow-lg shadow-gray-300" src="https://www.openstreetmap.org/export/embed.html?bbox=8.560591936111452%2C40.7250004634227%2C8.56413245201111%2C40.72683597647796&amp;layer=mapnik&amp;marker=40.725918226278324%2C8.56236219406128"></iframe><br/>
            <small><a className="hover:text-orange-500" href="https://www.openstreetmap.org/?mlat=40.725918&amp;mlon=8.562362#map=19/40.725918/8.562362"target="_blank">{t("click for fullscreen map")}</a></small>

            </div>


        </div>
    );
}
