import React from "react";
import { t } from "i18next";
interface PartnerLogo {
    id: number;
    imageUrl: string;
    altText: string;
}

const clientLogos: PartnerLogo[] = [
    { id: 1, imageUrl: "/partners/Apple_logo_black.svg", altText: "Apple" },
    { id: 2, imageUrl: "/partners/500px-698px-Samsung_Logo.svg.png", altText: "Samsung" },
    { id: 3, imageUrl: "/partners/microsoft-logo.png", altText: "microsoft" },
    { id: 4, imageUrl: "/partners/logo-blackmagic.png", altText: "BLACKMAGIC DESIGN" },
    { id: 5, imageUrl: "/partners/1000px-ASUS_Logo.svg.png", altText: "ASUS" },
    { id: 6, imageUrl: "/partners/LOGI_BIG.D-3f288e21.png", altText: "Logitech" },
    { id: 7, imageUrl: "/partners/BrightSign_logo.jpg", altText: "BrightSign" },
    { id: 8, imageUrl: "/partners/logo-panasonic.png", altText: "PANASONIC" },
    { id: 9, imageUrl: "/partners/EPSON.png", altText: "EPSON" },
    { id: 10, imageUrl: "/partners/GL-LOGO_PNG-Format.png", altText: "GL" },
    { id: 11, imageUrl: "/partners/logo-zyxel.png", altText: "Zyxel" },
    { id: 12, imageUrl: "/partners/Logo_hp.png", altText: "HP" },
    { id: 13, imageUrl: "/partners/Barco-Logo.png", altText: "BARCO" },
    { id: 14, imageUrl: "/partners/optoma-logo.png", altText: "Optoma" },
    { id: 15, imageUrl: "/partners/axis-logo_rev-01.png", altText: "AXIS" },
    { id: 16, imageUrl: "/partners/jbllogo.png", altText: "JBL" },
    { id: 17, imageUrl: "/partners/logo-bose.png", altText: "BOSE" },
    { id: 18, imageUrl: "/partners/Sennheiser-Logo.png", altText: "Sennheiser" },
    { id: 19, imageUrl: "/partners/logo-mirasys.png", altText: "MIRASYS" },
    { id: 20, imageUrl: "/partners/logo-shure.png", altText: "SHURE" },
    { id: 21, imageUrl: "/partners/logo-uniview.png", altText: "INIVIEW LED" },
    { id: 22, imageUrl: "/partners/800px-Cisco_logo.svg.png", altText: "Cisco" },
    { id: 23, imageUrl: "/partners/logo-soundcraft.png", altText: "SOUNDCRAFT" },
    { id: 24, imageUrl: "/partners/Leap_Motion_logo.png", altText: "Leap Motion" },

    
];

const PartnerGrid: React.FC = () => {
    return (
        <div className="flex flex-col items-center relative space-y-10 pt-20">
            <div className="md:flex justify-center w-full">
                <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-7 2xl:gap-[6rem] text-white h-full w-auto lg:mx-10 animate-fadeIn delay-9 ">
                    <div className="col-span-full flex flex-col justify-center items-center pb-16">
                        <h1 className="text-4xl md:text-6xl uppercase text-center ">{t("OUR TECHNICAL PARTNERS")}</h1>
                        <p className="font-mono text-center py-4">{t("We collaborate with leading hardware providers to deliver the best-in-class digital signage solutions.")}</p>
                    </div>
                    {clientLogos.map((logo) => (
                        <div
                            key={logo.id}
                            className="flex flex-col items-center lg:my-4 my-[3rem]  w-full"
                            data-aos="fade-zoom-in"
                            data-aos-offset="250"
                            data-aos-delay="50"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="300"
                            data-aos-once="true"
                        >
                            <div className="flex items-center justify-center w-[17rem] sm:w-auto 2xl:w-[17rem] xl:w-[16rem]">
                                <img
                                    className="object-contain  h-[3rem] sm:h-[10rem] 2xl:h-[5rem] px-[3rem] w-auto hover:scale-110 "
                                    src={logo.imageUrl}
                                    alt={logo.altText}
                                   style={{ filter: "brightness(0) invert(1)" }} // Makes the logo white
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PartnerGrid;
