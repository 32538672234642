import ImageText from "../common/ImageText";
import Counter from "../common/Counter";
import ProductDescription from "../common/ProductDescription";
import Collapsible from "../common/Collapsible";
import TechSpecs from "../common/TechSpecs";
import ProductSlider from "../common/ProductSlider";
import EmailPopup from '../common/EmailPopup';
import "../global.css";
import { Link } from "react-router-dom";
import React, {useEffect, useState} from "react";
import OverlayForm from "../components/OverlayForm";
import {Canvas} from "@react-three/fiber";
import GLTFModel from "../common/configuratore/GLTFModel";
import {Environment, OrbitControls} from "@react-three/drei";
import {Bloom, EffectComposer, TiltShift} from "@react-three/postprocessing";
import PageHeader from "../components/PageHeader";
import BlackButtons from "../common/BlackButtons";
import ImageTextHome from "../common/ImageText_Home";
import CareersSlider from "../common/CareersSlider";
import HeritageSlider from "../common/HeritageSlider";
import ProductWebForm from "../components/PipedriveForm";
import ExhibitSlider from "../common/ExhibitSlider";
import SignageSlider from "../common/ExhibitSlider";
import PartnerGrid from "../common/PartnerGrid";
import SectionsHeader from "../components/SectionsHeader";
import {useTranslation} from 'react-i18next'
export default function IT() {
  const {t}=useTranslation();
  useEffect(() => {
    if (window.location.hash) {
      let newPath = window.location.pathname + window.location.search;
      if (newPath.endsWith('/')) {
        console.log("termina con /", newPath)
        newPath = newPath.slice(0, -1);
        console.log("newPath", newPath)
      }
      window.history.pushState("", document.title, newPath);
    }
  }, []);

  const [isPopupOpen, setIsPopupOpen] = useState(false);


  const handleRequestClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>

      <div className="relative flex flex-col h-full  mt-20">
        {/* TITLE Section */}
        <div className="h-screen flex flex-col   z-40 opacity-0 animate-fadeIn delay-1  ">
          <div className="absolute inset-0  top-0 left-0 w-auto h-screen z-0 opacity-70 " >
            {/*<img src="/projects/MARTINI LEDWALL/CASA_MARTINI_0.jpg" alt="sara" className="object-cover w-full h-full" />*/}
            <img src="/projects/IT/IT.JPG" alt="sara" className="object-cover w-full h-full " />

          </div>


        <div className="flex flex-col">
          <SectionsHeader title={t("IT ASSISTANCE")} subtitle={t("Comprehensive Support for All Your Technology Needs")}/>

          <div className="absolute inset-0 h-screen w-auto">
            <div className="relative flex justify-center items-center h-screen w-auto pt-20 z-40"> <BlackButtons to1="/services/it/#infos"  description1={t("what we do")} to2="/partners" description2={t("tech partners")} /></div>

          </div>
        </div>
      </div>
      <div id="infos"/>
        <div className="mx-12 mb-4 mt-4 h-full flex flex-col bg-opacity-50 z-40 mt-40 pt-20">
          <div>
            <div className="grid  md:grid-rows-2 gap-4 sm:grid-cols-1 gap-y-20 ">
              <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <ImageTextHome
                    text={t("WHAT WE DO")}
                    imageSrc="/LogoRibes_In_Out - Copy.gif"
                description={t("We provide professional IT assistance and support services designed to ensure your systems are running smoothly, securely, and efficiently. Our team handles everything from troubleshooting and hardware maintenance to network management and cybersecurity. Whether you’re a small business, museum, or large corporation, we offer tailored IT solutions to meet your specific needs, reducing downtime and improving overall productivity.")} textAlign="left"
                    />
              </div>
              <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <ImageTextHome
                    text={t("HOW WE DO IT")}
                    imageSrc="/LogoRibes_In_Out - Copy.gif"
                    description={t("We begin by conducting a thorough analysis of your IT infrastructure, identifying potential weaknesses or areas for improvement. Our team then creates a customized plan for IT support, including proactive monitoring, troubleshooting, and regular maintenance. We offer both on-site and remote support to address issues quickly and minimize disruptions to your operations. Additionally, we provide cybersecurity audits and implement data protection measures to safeguard your information and systems.")} textAlign="right"
                    reverse={true}
                />
              </div>

            </div>
          </div>
        </div>



        <div id='main-features' className="h-20"></div>

        {/* OPEN POSITIONS Section */}
        <div className="px-10">
          <div
            className="flex items-center justify-center"
            data-aos="fade-zoom-in"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            {/*immagine product*/}
            <img 
              src="/projects/IT/it_2.jpg"
              className="w-auto md:w-5/12  h-auto "
                 alt="sara"
            />
          </div>
          <div>
            {/*cta download*/}
            <div
              className="flex flex-col  sm:flex-row justify-center items-center gap-2 md:gap-24  font-bold  "
              data-aos="fade-zoom-in"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >



            </div>
          </div>

          <div className="py-20 " ></div>
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" mb-12 text-5xl leading-120 tracking-tighter text-center uppercase  text-white " >
              {t("SERVICES WE PROVIDE")}

          </h1>

          <div data-aos="fade-zoom-in"
               data-aos-offset="100px"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600"
               data-aos-once="true">
            <ProductDescription
                title={t("Help Desk Support")}
                description={t("Immediate assistance for troubleshooting, technical issues, and system malfunctions through remote or on-site support. Our help desk services provide quick and effective solutions to keep your operations running smoothly and minimize downtime.")}
            /></div>

          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Hardware and Software Maintenance")}
                description={t("Regular updates, repairs, and maintenance for both hardware and software systems to ensure optimal performance and reduce the risk of failures. Our maintenance services help extend the lifespan of your IT equipment and keep your software up to date with the latest features and security patches.")}
            />
          </div>

          <div data-aos="fade-zoom-in"
               data-aos-offset="100px"
               data-aos-delay="500"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600"
               data-aos-once="true">
            <ProductDescription
                title={t("Network Management")}
                description={t("Monitoring and managing network performance, troubleshooting issues, and ensuring secure and stable connectivity across your organization. Our network management services help optimize your network infrastructure for improved efficiency and reliability.")}
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Cybersecurity Solutions")}
                description={t("Implementation of firewalls, antivirus software, encryption, and other security measures to protect against cyber threats and unauthorized access. Our cybersecurity solutions help safeguard your data, systems, and network from potential breaches and cyberattacks.")}
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Backup and Disaster Recovery")}
                description={t("Data backup solutions and disaster recovery plans to prevent data loss and ensure swift recovery in the event of a system failure. Our backup and recovery services help protect your critical data and minimize downtime, allowing you to resume operations quickly and efficiently.")}
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("IT Infrastructure Upgrades")}
                description={t("Evaluation and upgrading of existing IT infrastructure, including hardware replacements, software updates, and network enhancements. Our infrastructure upgrade services help modernize your systems, improve performance, and ensure compatibility with the latest technologies.")}
            />
          </div> <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Cloud Services")}
                description={t("Assistance with setting up and managing cloud storage, cloud computing solutions, and migration of data to the cloud for greater flexibility and security. Our cloud services help optimize your IT resources, reduce costs, and enhance scalability for your business operations.")}
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Server Installation and Maintenance")}
                description={t("Installation and ongoing maintenance of servers to support large-scale data management, internal applications, and network services. Our server solutions ensure reliable performance, data security, and efficient resource allocation for your business operations.")}
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Managed IT Services")}
                description={t("Comprehensive IT management, including monitoring, system administration, and strategic planning for long-term IT needs. Our managed services provide ongoing support and guidance to help you optimize your IT infrastructure, reduce costs, and improve operational efficiency.")}
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("IT Consulting and Audits")}
                description={t("Professional advice and audits to assess your current IT systems, identify risks, and recommend improvements for performance and security. Our consulting services help you make informed decisions about your IT infrastructure and implement best practices for optimal results.")}
            />
          </div>

        </div>




        {/*ANIMATED BLOCKS*/}

        <div className="  h-full flex flex-col bg-black bg-opacity-50 z-0 my-20">

          <div className=" grid grid-cols-1 md:grid-cols-3 gap-4  sm:mt-0 text-white">
            <Counter type="power" start={1} end={26} unit={t("y")} />
            <Counter type="letter" start="" end="TECH" />
            <Counter type="watt" start={100} end={1000} unit="" />
          </div>
        </div>

        

        {/*AVAILABLE VERSIONS Section*/}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 ">
          {/* Your content here */}
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase f text-white ">
              {t("DO YOU NEED ASSISTANCE?")}
          </h1>

          <div>
            <div className="grid  md:grid-cols-3  gap-4 px-4 sm:grid-cols-1">

              <div className=" ">
                {/*bg-green-500*/}
                <div className="value-item p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="800"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                      imageSrc="/icons/desktop_windows (1).svg"
                      title={t("we can install that")}
                      description={t("We provide comprehensive hardware installation, integrating all\n components seamlessly for optimal performance and synchronization.")}
                      textAlign="center"
                  />
                </div>
              </div>

              <div className="">
                {/*bg-green-500*/}

                <div className="value-item p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="500"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/icons/stylus_note (1).svg"
                    title={t("we can UPDATE that")}
                    description={t("We create custom designs, from exhibition layouts to digital interfaces,\n ensuring visually engaging and functional results.Each wing controlled independently")}
                    textAlign="center"
                  />
                </div>
              </div>



              <div className="value-item  p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                   data-aos="fade-zoom-in"
                   data-aos-offset="100px"
                   data-aos-delay="1100"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600" >
                <ImageText
                  imageSrc="/icons/volunteer_activism.svg"
                  title={t("we can help you overtime")}
                  description={t("We build advanced, custom software and hardware solutions and we take care of your needs overtime.")}
                  textAlign="center"
                />
              </div>
            </div>
          </div>
        </div>
                  <div id="tech-specs" className="h-10"></div>



        {/* VIDEO Section */}
        {/*<div className="opacity-0 animate-fadeIn delay-0 h-full flex flex-col bg-black bg-opacity-50 z-40">


        <div>
          <div>

            <video
                className="inset-0 w-screen h-full object-cover"
                loop={true}
                autoPlay={false}
                controls
            >
              <source src="/SARA_2.0_DIDASCALIE.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>*/}

        {/*FORM SECTION*/}
        <div className="max-w-md mx-auto mt-0 px-6 "  data-aos="fade-zoom-in"
             data-aos-offset="300px"
             data-aos-delay="0"
             data-aos-easing="ease-in-sine"
             data-aos-duration="600">
          <h1
              id='positions'
              className="opacity-0 animate-fadeIn delay-2 my-12 text-5xl leading-120 text-center uppercase font-medium text-white ">
              {t("LET'S TALK ABOUT IT!")}
          </h1>
          <ProductWebForm
              formId="95a0e1ff-e505-4b30-9253-a3f16bc466a6"
              portalId="145047450"
              region="eu1"
          />
        </div>



        <div className="mt-40  z-40 bg-black">
          <Link to="/contact">
            <div className="flex items-center justify-center ">
              <button
                  className="font-mono w-auto h-10 text-center text-white  py-2 px-4 transform transition-transform duration-200 hover:scale-110"
                  style={{
                    background: "linear-gradient(90deg, #000000 0%, #eb6000 100%)",
                  }}
              >
              {t("CONTACT US")}
              </button>
            </div>
          </Link>
        </div>

      </div>
      {/* {isPopupOpen && fileType && (
          <EmailPopup fileType={fileType} onClose={handleClosePopup} />
      )}*/}
      {isPopupOpen && (
          <OverlayForm
              formUrl="https://webforms.pipedrive.com/f/6UR9Re8nR5VKrsxbJHNTBDLcsXv6xjIStMZYDU4iiOvEJdEo7cZwg4M1uAm9NAG7WX"
              onClose={handleClosePopup}
          />
      )}
    </div>
  );
}
