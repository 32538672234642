import ImageText from "../common/ImageText";
import Counter from "../common/Counter";
import ProductDescription from "../common/ProductDescription";
import Collapsible from "../common/Collapsible";
import TechSpecs from "../common/TechSpecs";
import ProductSlider from "../common/ProductSlider";
import EmailPopup from '../common/EmailPopup';
import "../global.css";
import { Link } from "react-router-dom";
import React, {useEffect, useState} from "react";
import OverlayForm from "../components/OverlayForm";
import {Canvas} from "@react-three/fiber";
import GLTFModel from "../common/configuratore/GLTFModel";
import {Environment, OrbitControls} from "@react-three/drei";
import {Bloom, EffectComposer, TiltShift} from "@react-three/postprocessing";
import PageHeader from "../components/PageHeader";
import BlackButtons from "../common/BlackButtons";
import ImageTextHome from "../common/ImageText_Home";
import CareersSlider from "../common/CareersSlider";
import HeritageSlider from "../common/HeritageSlider";
import ProductWebForm from "../components/PipedriveForm";
import SectionsHeader from "../components/SectionsHeader";
import {useTranslation} from 'react-i18next'
export default function Industry40() {
  const {t}=useTranslation();
  useEffect(() => {
    if (window.location.hash) {
      let newPath = window.location.pathname + window.location.search;
      if (newPath.endsWith('/')) {
        console.log("termina con /", newPath)
        newPath = newPath.slice(0, -1);
        console.log("newPath", newPath)
      }
      window.history.pushState("", document.title, newPath);
    }
  }, []);






  return (
    <div>

      <div className="relative flex flex-col h-full  mt-20">
        {/* TITLE Section */}
        <div className="h-screen flex flex-col   z-40 opacity-0 animate-fadeIn delay-1 ">
          <div className="absolute inset-0  top-0 left-0 w-auto h-screen z-40 opacity-70" >



            <video src="/digital-revolution-it-administrator-activating-mod-2023-11-27-05-31-08-utc.mp4" className=" w-full h-full object-cover" autoPlay={true} loop={true} muted playsInline/>

          </div>

        <div className="flex flex-col">
          <SectionsHeader title={t("INDUSTRY 4.0")} subtitle={t("Transforming Manufacturing with Advanced Technology")}/>

          <div className="absolute inset-0 h-screen w-auto">
            <div className="relative flex justify-center items-center h-screen w-auto pt-20 z-40"> <BlackButtons to1="/services/industry-40/#infos"  description1={t("WHAT WE DO")} to2="/case-history" description2={t("CASE HISTORY")} /></div>

          </div>
        </div>
        </div>
        <div id="infos"/>
        <div className="mx-12 mb-24 h-full flex flex-col bg-opacity-50 mt-40 z-40 pt-20">
          <div>
            <div className="grid  md:grid-rows-2 gap-4 sm:grid-cols-1 gap-y-20 ">
              <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <ImageTextHome
                    text={t("WHAT WE DO")}
                    imageSrc="/LogoRibes_In_Out - Copy.gif"
                    description={t("We design and implement interactive and new media solutions tailored for Industry 4.0, blending cutting-edge technology with creative innovation. Our services aim to revolutionize manufacturing processes by integrating smart systems, automation, and interactive platforms that enhance the production experience. We focus on improving operational efficiency, optimizing data-driven decision-making, and creating engaging, real-time interfaces for better human-machine collaboration.")}
                    textAlign="left"
                />
              </div>
              <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <ImageTextHome
                    text={t("HOW WE DO IT")} 
                    imageSrc="/LogoRibes_In_Out - Copy.gif"
                    description={t("Our approach starts with understanding your unique business needs and goals. We integrate advanced technologies such as IoT, AI, and digital media to build smart, connected environments that interact with machines, data, and operators. Our solutions offer real-time data visualization, intuitive human-machine interfaces (HMIs), and interactive displays that empower users to monitor, control, and optimize their production processes. With continuous monitoring and support, we ensure that your systems remain adaptive and future-proof in an evolving technological landscape.")}
                    textAlign="right"
                    reverse={true}
                />
              </div>
            </div>
          </div>
        </div>



        <div id='main-features' className="h-20"></div>

        {/* OPEN POSITIONS Section */}
        <div className="px-10">
          <div
            className="flex items-center justify-center"
            data-aos="fade-zoom-in"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            {/*immagine product*/}
            <img 
              src="/projects/EXTRA/industry.png"
              className="w-auto md:w-5/12  h-auto "
                 alt="car"
            />
          </div>
          <div>
            {/*cta download*/}
            <div
              className="flex flex-col  sm:flex-row justify-center items-center gap-2 md:gap-24  font-bold  "
              data-aos="fade-zoom-in"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >



            </div>
          </div>

          <div className="py-20 " id="infos"></div>
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" mb-12 text-5xl leading-120 tracking-tighter text-center uppercase  text-white " >
            {t("WHAT WE CAN DO")}{" "}

          </h1>

          <div data-aos="fade-zoom-in"
               data-aos-offset="100px"

               data-aos-easing="ease-in-sine"
               data-aos-duration="600"
               data-aos-once="true">
            <ProductDescription
                title={t("Interactive Data Visualization Platforms")}
                description={t("We create interactive dashboards that provide real-time data visualization of production metrics, allowing operators to monitor key performance indicators (KPIs) and make informed decisions at a glance."  )}        />
          </div>

          <div data-aos="fade-zoom-in"
               data-aos-offset="100px"
               data-aos-delay="500"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600"
               data-aos-once="true">
            <ProductDescription
                title={t("Smart IoT Devices and Sensors")}
                description={t("Our IoT solutions include smart devices that monitor everything from equipment performance to environmental conditions. These sensors provide real-time feedback and data collection for predictive maintenance and operational efficiency.")}
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Augmented Reality (AR) for Training and Maintenance")}
                description={t("Using AR, we create interactive training modules and maintenance guides that help operators understand complex machinery and processes. This immersive approach improves learning and reduces downtime during maintenance.")}
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Digital Twins for Interactive Simulations")}
                description={t("Our digital twin technology allows businesses to create virtual replicas of physical assets, enabling real-time simulations and testing. Users can interact with the digital twin to optimize processes, test scenarios, and predict outcomes before implementing changes on the factory floor.")}
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Cloud-Connected Interactive Systems")}
                description={t("We offer cloud-based solutions that enable centralized control and monitoring of production processes across multiple locations. These systems allow for remote management and real-time collaboration between teams using interactive, web-based interfaces.")}
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Immersive Multimedia Displays")}
                description={t("We integrate large-scale, immersive displays into production environments, providing dynamic visual feedback on system performance and operational data, enabling interactive decision-making right on the factory floor.")}
            />
          </div> <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Smart Factory Solutions with Interactive Control Centers")}
                description={t("We create centralized, interactive control centers that manage and monitor every aspect of a smart factory. These interfaces allow operators to interact with various systems, improving oversight and efficiency.")}
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Interactive Tables")}
                description={t("We design and implement interactive tables that allow operators to engage directly with digital content, offering touch-enabled surfaces for navigating data, controlling systems, and monitoring operations in real-time.")}
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("E-Learning Platforms")}
                description={t("We develop customized e-learning platforms to facilitate interactive training programs for staff, helping businesses integrate ongoing education and skill development directly into their operations.")}
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Touchless systems")}
                description={t("Touchless systems allow visitors to interact with products without physically touching any surfaces, using gestures, voice commands, or sensors. These systems are particularly valuable in enhancing accessibility and hygiene in public spaces. By using motion sensors or voice recognition technology, visitors can navigate through information, activate multimedia content, or control interactive displays effortlessly.")}
            />
          </div>


        </div>

        {/* PRODUCT GALLERY Section */}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 " id="gallery">
          {/*  
          <h1
              data-aos="fade-zoom-in"
              data-aos-offset="300px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" mt-20 mb-40  text-5xl leading-120 tracking-tighter text-center uppercase text-white ">
            {t("PRODUCT GALLERY")}{" "}
          </h1>
          
          <div data-aos="fade-zoom-in"
               data-aos-offset="300px"
               data-aos-delay="300"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600">
            <HeritageSlider />
          </div>*/}

        </div>

        {/*ANIMATED BLOCKS*/}

        <div className="  h-full flex flex-col bg-black bg-opacity-50 z-0 my-20">

          <div className=" grid grid-cols-1 md:grid-cols-3 gap-4  sm:mt-0 text-white">
            <Counter type="power" start={1} end={26} unit={t("y")} />
            <Counter type="letter" start="" end="TECH" />
            <Counter type="watt" start={100} end={1000} unit="" />
          </div>
        </div>

        {/*AVAILABLE VERSIONS Section*/}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 ">
          {/* Your content here */}
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase f text-white ">
            {t("DO YOU HAVE AN IDEA?")}
          </h1>

          <div>
            <div className="grid  md:grid-cols-3  gap-4 px-4 sm:grid-cols-1">
              <div className="">
                {/*bg-green-500*/}
                <div className="value-item p-4  transform transition-transform duration-300 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)] ease-in-out"
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="500"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/icons/stylus_note (1).svg"
                    title={t("we can design that")}
                    description={t("We create custom designs, from exhibition layouts to digital interfaces,\n ensuring visually engaging and functional results.Each wing controlled independently")}
                    textAlign="center"
                  />
                </div>
              </div>

              <div className=" ">
                {/*bg-green-500*/}
                <div className="value-item p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="800"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/icons/desktop_windows (1).svg"
                    title={t("we can project hardware")}
                    description={t("We provide comprehensive hardware installation, integrating all\n components seamlessly for optimal performance and synchronization.")}
                    textAlign="center"
                  />
                </div>
              </div>

              <div className="value-item  p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                   data-aos="fade-zoom-in"
                   data-aos-offset="100px"
                   data-aos-delay="1100"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600" >
                <ImageText
                  imageSrc="/icons/sdk (1).svg"
                  title={t("we can develop software")}
                  description={t("We build advanced, custom software solutions that deliver flawless\n interactive experiences tailored to your project needs.")}
                  textAlign="center"
                />
              </div>
            </div>
          </div>
        </div>
                  <div id="tech-specs" className="h-10"></div>
        {/*
        <div  className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40">

          <h1
              data-aos="fade-zoom-in"
              data-aos-offset="300px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-bold text-white ">
            TECHNICAL SPECIFICATIONS{" "}
          </h1>

          <div>
            <div className="grid  md:grid-cols-4  gap-4 px-4 sm:grid-cols-1 " >

              <div
                  data-aos="fade-zoom-in"
                  data-aos-offset="300px"
                  data-aos-delay="400"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600">
                <TechSpecs
                    title="MECHANICAL SPECS"
                    description={[
                      { title: "Size: ", description: "97x97x23mm" },
                      { title: "Mass: ", description: "0.3kg" },
                      { title: "Rotation range: ", description: " +/- 180°" },
                      {
                        title: "Nominal rotation speed: ",
                        description: " 10 deg/s",
                      },
                    ]}

                />
              </div>
              <div
                  data-aos="fade-zoom-in"
                  data-aos-offset="300px"
                  data-aos-delay="700"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <TechSpecs
                    title="ELECTRICAL SPECS"
                    description={[
                      { title: "Power supply:  ", description: "12V (unregulated 10-18V)" },
                      { title: "power consumption:  ", description: "Idle: 0.2W\nHOLD: 2W\nACTUATION: 5W" },
                      { title: "power transfer type: ", description: "flex pcb (twist capsule)" },
                      {
                        title: "Maximum power transfer: ",
                        description: "500W @42V",
                      },
                      {
                        title: "Communication interface:  ",
                        description: "RS485 / CAN",
                      },
                    ]}

                />
              </div>
              <div
                  data-aos="fade-zoom-in"
                  data-aos-offset="300px"
                  data-aos-delay="1000"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <TechSpecs
                    title="THERMAL SPECS"
                    description={[
                      { title: "Operating  temperature range: ", description: "-40/+60 °C" },
                      { title: "SURVIVAL temperature range: ", description: "-50/+70 °C" },

                    ]}

                />
              </div>
              <div
                  data-aos="fade-zoom-in"
                  data-aos-offset="300px"
                  data-aos-delay="1300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <TechSpecs
                    title="MODES OF OPERATIONS"
                    description={[
                      { title: "Autonomous  ", description: "sun tracking based on sara’s sun sensor data" },
                      { title: "Freeze ", description: "pauses autonomous actuation for a given time" },
                      { title: "Slave  ", description: " target angle set by the on-board computer" },
                      {
                        title: "Safe ",
                        description: " panels are (mechanically) returned to the reference angle by snapback",
                      },
                    ]}

                />
              </div>
            </div>
          </div>
        </div>*/}


        {/* VIDEO Section */}
        {/*<div className="opacity-0 animate-fadeIn delay-0 h-full flex flex-col bg-black bg-opacity-50 z-40">


        <div>
          <div>

            <video
                className="inset-0 w-screen h-full object-cover"
                loop={true}
                autoPlay={false}
                controls
            >
              <source src="/SARA_2.0_DIDASCALIE.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>*/}

        {/*FORM SECTION*/}
        <div className="max-w-md mx-auto mt-0 px-6 "  data-aos="fade-zoom-in"
             data-aos-offset="300px"
             data-aos-delay="0"
             data-aos-easing="ease-in-sine"
             data-aos-duration="600">
          <h1
              id='positions'
              className="opacity-0 animate-fadeIn delay-2 my-12 text-5xl leading-120  text-center uppercase font-medium text-white ">
            {t("LET'S TALK ABOUT IT!")}
          </h1>
          <ProductWebForm
              formId="95a0e1ff-e505-4b30-9253-a3f16bc466a6"
              portalId="145047450"
              region="eu1"
          />
        </div>



        <div className="mt-40  z-40 bg-black">
          <Link to="/contact">
            <div className="flex items-center justify-center ">
              <button
                  className="font-mono w-auto h-10 text-center text-white  py-2 px-4 transform transition-transform duration-200 hover:scale-110"
                  style={{
                    background: "linear-gradient(90deg, #000000 0%, #eb6000 100%)",
                  }}
              >
                {t("CONTACT US")}
              </button>
            </div>
          </Link>
        </div>

      </div>
    </div>
  );
}
