import React from "react";

interface ProductDescriptionProps {
    title: string;
    description?: string;
    icon?: string;
}

class ProductDescription extends React.Component<ProductDescriptionProps> {
    render() {
        const { title, description, icon } = this.props;

        return (
         <>
             {icon && (<div className=" grid md:grid-cols-3 sm:grid-row gap-4 text-white w-full py-8 px-10 xl:px-32 hover:scale-110 transform transition-transform duration-300 ease-in-out ">
                 <div className="col-span-1 flex flex-col  justify-center ">
                     <div className="text-4xl md:text-2xl lg:text-4xl  text-left uppercase ">{title}</div>
                 </div>
                 <div className="font-mono uppercase flex items-center  text-left w-full h-auto text-xl ">
                     <p>{description}</p>
                 </div> <div className="uppercase flex justify-center  w-full h-auto scale-150">
                 <img src={icon} alt="icon" className=""></img>
             </div>
             </div>) }
             {!icon && (<div className=" grid md:grid-cols-2 sm:grid-row gap-4 text-white w-full py-8 px-10  xl:px-32 hover:scale-110 transform transition-transform duration-300 ease-in-out">
                 <div className="col-span-1 flex flex-col  justify-center ">
                     <div className="text-4xl md:text-2xl lg:text-4xl  text-left uppercase ">{title}</div>
                 </div>
                 <div className="font-mono uppercase flex items-center  text-left w-full h-auto text-xl">
                     <p>{description}</p>
                 </div>
             </div>) }</>
        );
    }
}

export default ProductDescription;
