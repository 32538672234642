import React from "react";
import { Link } from "react-router-dom";
import NewsletterForm from "../components/NewsletterForm";
import ProductForm from "../components/ProductForm";
import PipedriveForm from "../components/PipedriveForm";
import LeadForm from "../components/LeadForm";
import { t } from "i18next";
const Footer: React.FC = () => {
  return (
    <div className="bg-black">
      {/* <NewsletterForm/>
            <PipedriveForm formUrl="https://webforms.pipedrive.com/f/72sjlnxg8yT7or0rAmWhSbltuXooqvkv8FnNiHPKxwM8y2QSxCNHuk3U2scGpqE5Yn"/>
            <PipedriveForm formUrl="https://webforms.pipedrive.com/f/cs5ar6a5ZlhoJ5o3htH9CTN6HuVDKofOQ0zBr3yEb9bNHHO9YsEALWl07mUCm49xGb"/>*/}
      {/*  <ProductForm title={"product sara"} />*/}

      <footer className="font-mono grid grid-cols-1 md:grid-cols-5 text-center sm:text-left py-4 pt-32 text-white">
        <div className="flex flex-col space-y-4 md:items-left md:ml-[3rem]">
          <Link to="/home">
            <img
                className="max-h-20 relative"
                src="/R_ribes.png"
                alt="Ribes Digilab Logo"
            />
          </Link>
          
        </div>



        




        <div className="grid grid-cols-1 col-span-3 pt-5 md:pt-0 sm:flex sm:flex-row  space-y-10 sm:space-y-0 sm:space-x-4 justify-between px-6">
          <div>
            <a href="/home" className="block underline">
              {t("HOME")}
            </a>
            <a href="/about" className="block underline">
              {t("ABOUT")}
            </a>
            <a href="/careers" className="block underline">
              {t("CAREERS")}
            </a>
            <a href="/clients" className="block underline ">
              {t("TRUSTED BY")}
            </a>
            <a href="/contact" className="block underline ">
              {t("GET IN TOUCH")}
            </a>
          </div>


          <div>
            <h2 className="text-1xl  mb-2 uppercase ">
            {t("administrative address:")}
            </h2>
            <p className="uppercase">Lungo Dora Colletta 81/A <br/> 10153 Torino <br/> Italy</p>
          </div>

          <div>
            <h2 className="text-1xl mb-3 uppercase">
              {t("OTHER address:")}
            </h2>
            <p className="uppercase">Corso Toscana 110/A<br/> 10149 Torino <br/>Italy</p>

          </div>

          <div>
            <h2 className="text-1xl mb-3 uppercase">
              {t("OTHER address:")}
            </h2>
            <p className="uppercase">Piazza del rosario 9<br/> 07100 SASSARI <br/>SARDEGNA</p>

          </div>
          
        </div>


        <div className=" flex grid  space-y-4 justify-around">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://it.linkedin.com/company/ribes-digilab"
            >
              <img className="h-10 hover:scale-110" src="/footer_icons/Group.svg" alt="Footer 2" />
            </Link>


            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://www.instagram.com/ribesdigilab/"
            >
              <img className="h-10 hover:scale-110 " src="/footer_icons/logo-instagram.png" style={{ filter: "brightness(0) invert(1)" }}  alt="Footer 3" />
            </Link>
            


            {/*<img src="\footer_icons\path1009.svg" alt="Footer 3" />*/}
          </div>
        


      </footer>
    </div>
  );
};

export default Footer;
